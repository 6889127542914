/* global Tabulator */

const { delay, booleanFormatter } = require('./utils');

/**
 * Configure bootstrap dropdown to work correct.
 *
 * @param {string} selector Selector of an element to work with.
 *  WARN: jquery method is used. Make sure your element will be the first in the DOM.
 * @param {Tabulator.ColumnDefinition[]} columns
 */
function configureDropdownWithCheckboxes(selector, columns, onElementToggle) {

  const filterInput = document.createElement('input');

  filterInput.setAttribute('type', 'text');
  filterInput.setAttribute('aria-label', 'Input to search through columns');
  filterInput.classList.add('form-control');

  const listItem = document.createElement('li');

  listItem.classList.add('dropdown-item');
  listItem.appendChild(filterInput);
  $(selector).append(listItem);

  columns
    .filter(col => !!col.title)
    .forEach(col => {
      const listItem = document.createElement('li');
      const label = document.createElement('label');
      const checkbox = document.createElement('input');

      checkbox.type = 'checkbox';
      checkbox.setAttribute('data-field', col.field);
      if (col.visible !== false) {
        checkbox.setAttribute('checked', '');
      }
      label.appendChild(checkbox);
      label.append(col.title);

      listItem.classList.add('dropdown-item');
      listItem.appendChild(label);
      $(selector).append(listItem);
    });

  $(selector).on('change', 'input[type=\'checkbox\']', function handleSelectionChanged() {
    /** @type HTMLInputElement */
    const el = this;

    if (onElementToggle) {
      onElementToggle(el.dataset.field, el.checked);
    }
  });

  $(filterInput).on('input', function onFilterInputChanged() {
    /** @type HTMLInputElement */
    const el = this;
    const filterValue = el.value.toLowerCase();

    $(selector).find('li').has('input[type=\'checkbox\']').each(function hideListItems() {
      const optionText = $(this).children('label').text().toLowerCase();

      if (optionText.indexOf(filterValue) === -1) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  });

  $(selector).on('click', (e) => e.stopPropagation());
}

/**
 * Extract filters for field by key and remove filter with "" key.
 * @param {Object} filters
 * @param {keyof filters} key
 *
 * @description The point of this function is that Tabulator headerFilter works not well with a filter object
 *  that contains empty key { ..., "" : "", ... }. That's why the function is this file.
 */
function extractFieldFilters(filters, key, filterEmpty = false) {
  // const fieldFilters = JSON.parse(filters[key]);
  const fieldFilters = filters[key];

  if (filterEmpty && '' in fieldFilters) {
    delete fieldFilters[''];
  }

  return fieldFilters ?? {};
}

/**
 * Create element to display amount of rows in the tabulator table.
 */
function initPageDisplayElement() {
  /*
    There is no way to use HTMLElement object because if it's passed to 'footerElement'
     of the tabulator constructor, it overrides default pagination element.
  */
  const element = '<span id="pagesInfo" style="padding: 0 10px"></span>';

  return {
    element,
    updateInfo() {
      const el = document.getElementById('pagesInfo');

      el.innerHTML = `Page ${this.getPage()} of ${this.getPageMax()}`;
    },
  };
}

/**
 * Map sorters list to argument for "initialSort" param
 * @param {Array<Object>} sorters
 *
 * @description For unknown reasons when you select column, tabulator push this instance
 *  [{ dir: string, field: string }]. But if you want to provide value for "initialSort" property
 *  during table initialization, you should pass [{dir: string, column: string}].
 */
function mapSortersToSortParam(sorters) {
  return sorters.map(sort => ({
    dir: sort.dir,
    column: sort.field,
  }));
}

Tabulator.extendModule('edit', 'editors', {
  /**
   * Custom select for tabulator.
   * @param {Tabulator.CellComponent} cell
   * @param {Tabulator.EmptyCallback} onRendered
   * @param {Function} success
   * @param {Function} cancel
   * @param {Tabulator.EditorParams} editorParams
   */
  nrsSelect(cell, onRendered, success, cancel, editorParams) {
    /**
        * Option of multi-select.
        * @typedef {Object} NrsMultiSelectOption
        * @property {string} id ID of option.
        * @property {string} value Human-readable value.
        */

    /** Container with "relative" position to put "clear" button inside input. */
    const container = document.createElement('div');
    /** Main control. */
    const input = document.createElement('input');
    /** Icon to put inside clear button. */
    const icon = document.createElement('icon');
    /** Table cell element */
    const cellElement = cell.getElement();

    /**
     * Keep list element.
     * @type {HTMLUListElement}
     */
    let listElement;

    /** Whether a dropdown is opened or not. */
    let isOpened = false;

    /**
     * Selected value. ID of an option.
     */
    let selectedOption = cell.getValue();

    container.classList.add('nrs-multi-select-wrapper');

    icon.classList.add('fa', 'fa-times');

    /**
     * Check whether an item is selected.
     * @param {NrsMultiSelectOption} item Item
     */
    function isItemSelected(item) {
      return item.id === selectedOption;
    }

    /**
     * Parse option object to NrsMultiSelectOption array.
     *
     * @param {Record<string, string>} optionObj Object with options.
     *
     * @returns {NrsMultiSelectOption[]}
     */
    function parseItems(optionObj) {
      const optionsList = Object.keys(optionObj)
        .map(key => ({
          id: key,
          value: optionObj[key],
        }));

      // Add empty options if it's not presented.
      const emptyEl = optionsList.find(option => option.id === '');

      if (!emptyEl) {
        optionsList.push({
          id: '',
          value: '',
        });
      }

      return optionsList;
    }

    /**
     * Call 'success' callback with selected options.
     */
    function emitSelectedItem() {
      success(selectedOption);
    }

    /**
     * Fill main input with selected values.
     */
    function fillInputWithSelectedValue() {
      const allOptions = parseItems(editorParams.values);

      const selectedVal = allOptions.find(opt => opt.id === selectedOption)?.value;

      input.value = selectedVal ?? '';
    }

    /**
     * Hide list elements that don't start with 'filter'
     * @param {string} filter Value to filter.
     */
    function filterListElements(filter) {
      for (let i = 0; i < listElement.children.length; i++) {
        const item = listElement.children[i];
        const itemVal = item.innerText.toLowerCase();

        if (item.children.length === 0) {
          const isIncluded = itemVal.startsWith(filter.toLowerCase());

          item.toggleAttribute('hidden', !isIncluded);
        }
      }
    }

    /**
     * Create 'input' element for searching in list.
     * @param {Function} inputCb Callback that's fired on 'input' event.
     * @param {Function} blurCb Callback that's fired on 'blur' event.
     */
    function initSearchElement(inputCb, blurCb) {
      const searchInput = document.createElement('input');

      searchInput.type = 'text';

      searchInput.classList.add('form-control');
      searchInput.setAttribute('aria-label', 'Search through available options');

      searchInput.addEventListener('input', inputCb);

      searchInput.addEventListener('blur', blurCb);

      return searchInput;
    }

    /**
     * Close dropdown element.
     */
    function closeDropdown() {
      isOpened = false;
      listElement.parentElement.removeChild(listElement);
    }

    /**
     * Create DOM element for dropdown and fill it with values.
     * @param {Array<NrsMultiSelectOption>} allOptions
     * @param {Array<NrsMultiSelectOption>} selected
     */
    function createDropdownElement(allOptions) {
      const list = document.createElement('ul');

      list.style.margin = '0';
      list.style.padding = '4px';

      const onSearchInput = (e) => delay(filterListElements(e.target.value), 500);

      const onSearchBlur = (ev) => {
        if (ev.relatedTarget) {
          /** @type HTMLElement */
          const target = ev.relatedTarget;

          if (target.hasAttribute('nrsSelectorEl')) {
            return;
          }
        }
        /*
          To not handle logic by closing dropdown just focus on main input and blur to fire
           main close method.
        */
        input.focus();
        input.blur();
      };

      const searchControl = initSearchElement(onSearchInput, onSearchBlur);

      searchControl.style.marginBottom = '4px';

      const searchEl = document.createElement('li');

      searchControl.setAttribute('nrsSelectorEl', '');
      searchEl.appendChild(searchControl);
      list.appendChild(searchEl);

      allOptions.forEach(option => {
        const optionElement = document.createElement('li');

        optionElement.tabIndex = 0;
        optionElement.innerText = option.value ?? '';

        optionElement.classList.add('tabulator-edit-select-list-item');
        optionElement.setAttribute('nrsSelectorEl', '');

        optionElement.addEventListener('click', () => {
          input.focus();
          selectedOption = option.id;
          fillInputWithSelectedValue();
          emitSelectedItem();
          // Blur input to close dropdown.
          input.blur();
        });

        if (isItemSelected(option)) {
          optionElement.classList.add('active');
        }

        list.appendChild(optionElement);
      });
      list.classList.add('tabulator-edit-select-list');

      return list;
    }

    /**
     * Open dropdown element.
     */
    function openDropdown() {
      const allOptions = parseItems(editorParams.values)
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => a.id < b.id
          ? -1
          : a.id > b.id
            ? 1
            : 0
        );

      listElement = createDropdownElement(allOptions);

      const offset = cellElement.getBoundingClientRect();

      listElement.style.minWidth = `${cellElement.offsetWidth}px`;
      listElement.style.maxHeight = '500px';

      listElement.style.top = `${offset.top + cellElement.offsetHeight}px`;
      listElement.style.left = `${offset.left}px`;

      document.body.appendChild(listElement);
    }

    input.addEventListener('focus', () => {
      if (!isOpened) {
        openDropdown();
        isOpened = true;
      }
    });

    input.addEventListener('blur', (ev) => {
      if (ev.relatedTarget) {
        /** @type HTMLElement */
        const target = ev.relatedTarget;

        if (target.hasAttribute('nrsSelectorEl')) {
          return;
        }
      }
      closeDropdown();
    });

    const disableInteractivity = (e) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    };

    // Make sure users cannot type into the input element.
    input.addEventListener('keydown', disableInteractivity);
    input.addEventListener('paste', disableInteractivity);

    input.style.padding = '4px 1.6rem 4px 4px';
    input.style.width = '100%';
    input.style.boxSizing = 'border-box';
    input.style.cursor = 'default';

    container.appendChild(input);

    fillInputWithSelectedValue();

    return container;
  },

  /**
   * Custom select for tabulator.
   * @param {Tabulator.CellComponent} cell
   * @param {Tabulator.EmptyCallback} onRendered
   * @param {Function} success
   * @param {Function} cancel
   * @param {Tabulator.EditorParams} editorParams
   */
  nrsMultiSelect(cell, onRendered, success, cancel, editorParams) {

    /**
     * Option of multi-select.
     * @typedef {Object} NrsMultiSelectOption
     * @property {string} id ID of option.
     * @property {string} value Human-readable value.
     */

    /** Container with "relative" position to put "clear" button inside input. */
    const container = document.createElement('div');
    /** Main control. */
    const input = document.createElement('input');
    /** Button to unselect all options. */
    const clearButton = document.createElement('button');
    /** Icon to put inside clear button. */
    const icon = document.createElement('icon');
    /** Table cell element */
    const cellElement = cell.getElement();
    /**
     * Initial values.
     * @type Array<any>
     */
    const initialValue = cell.getValue();

    /**
     * Keep list element.
     * @type {HTMLUListElement}
     */
    let listElement;

    /** Whether a dropdown is opened or not. */
    let isOpened = false;

    /**
     * Selected options. Keep only IDs of options
     * @type Array<any>
     */
    const selectedOptions = [];

    container.classList.add('nrs-multi-select-wrapper');

    clearButton.setAttribute('title', 'Clear selected values');
    clearButton.appendChild(icon);

    icon.classList.add('fa', 'fa-times');

    // If have init value, push it to selected options.
    if (initialValue) {
      initialValue.forEach(val => selectedOptions.push(val));
    }

    /**
     * Toggle item. Add/remove within selectedOptions array.
     * @param {NrsMultiSelectOption} item Item to toggle
     */
    function toggleItem(item) {
      const itemIdx = selectedOptions.findIndex(opt => opt === item.value);

      if (itemIdx === -1) {
        selectedOptions.push(item.value);
      } else {
        selectedOptions.splice(itemIdx, 1);
      }
    }

    /**
     * Check whether an item is selected.
     * @param {NrsMultiSelectOption} item Item
     */
    function isItemSelected(item) {
      const itemIdx = selectedOptions.findIndex(opt => opt === item.id);

      return itemIdx !== -1;
    }

    /**
     * Parse option object to NrsMultiSelectOption array.
     *
     * @param {Record<string, string>} optionObj Object with options.
     *
     * @returns {NrsMultiSelectOption[]}
     */
    function parseItems(optionObj) {
      return Object.keys(optionObj)
        .map(key => ({
          id: key,
          value: optionObj[key],
        }));
    }

    /**
     * Call 'success' callback with selected options.
     */
    function emitSelectedItems() {
      success([...selectedOptions]);
    }

    /**
     * Fill main input with selected values.
     *
     * @description If selected less than 4 elements, values of these labels
     *  will be concatenated with comma. Otherwise "N selected" will be printed
     *  where N is amount of selected elements.
     */
    function fillInputWithSelectedValues() {
      const allOptions = parseItems(editorParams.values);

      if (selectedOptions.length > 3) {
        input.value = `${selectedOptions.length} selected`;
      } else {
        input.value = selectedOptions
          .map(sel => allOptions.find(opt => opt.id === sel))
          .map(val => val?.value)
          .join(',');
      }
    }

    /**
     * Diplay or hide clear button.
     */
    function toggleClearButton() {
      const areSelected = selectedOptions.length > 0;

      clearButton.toggleAttribute('hidden', !areSelected);
    }

    /**
     * Hide list elements that don't start with 'filter'
     * @param {string} filter Value to filter.
     */
    function filterListElements(filter) {
      for (let i = 0; i < listElement.children.length; i++) {
        const item = listElement.children[i];
        const itemVal = item.innerText.toLowerCase();

        if (item.children.length === 0) {
          const isIncluded = itemVal.startsWith(filter.toLowerCase());

          item.toggleAttribute('hidden', !isIncluded);
        }
      }
    }

    /**
     * Create 'input' element for searching in list.
     * @param {Function} inputCb Callback that's fired on 'input' event.
     * @param {Function} blurCb Callback that's fired on 'blur' event.
     */
    function initSearchElement(inputCb, blurCb) {
      const searchInput = document.createElement('input');

      searchInput.type = 'text';

      searchInput.classList.add('form-control');
      searchInput.setAttribute('aria-label', 'Search through available options');

      searchInput.addEventListener('input', inputCb);

      searchInput.addEventListener('blur', blurCb);

      return searchInput;
    }

    /**
     * Create DOM element for dropdown and fill it with values.
     * @param {Array<NrsMultiSelectOption>} allOptions
     * @param {Array<NrsMultiSelectOption>} selected
     */
    function createDropdownElement(allOptions) {
      const list = document.createElement('ul');

      list.style.margin = '0';
      list.style.padding = '4px';

      const onSearchInput = (e) => delay(filterListElements(e.target.value), 500);

      const onSearchBlur = (ev) => {
        if (ev.relatedTarget) {
          /** @type HTMLElement */
          const target = ev.relatedTarget;

          if (target.hasAttribute('nrsSelectorEl')) {
            return;
          }
        }
        /*
          To not handle logic by closing dropdown just focus on main input and blur to fire
           main close method.
        */
        input.focus();
        input.blur();
      };

      const searchControl = initSearchElement(onSearchInput, onSearchBlur);

      searchControl.style.marginBottom = '4px';

      const searchEl = document.createElement('li');

      searchControl.setAttribute('nrsSelectorEl', '');
      searchEl.appendChild(searchControl);
      list.appendChild(searchEl);

      allOptions.forEach(option => {
        const optionElement = document.createElement('li');

        optionElement.tabIndex = 0;
        optionElement.innerText = option.value ?? '';

        optionElement.classList.add('tabulator-edit-select-list-item');
        optionElement.setAttribute('nrsSelectorEl', '');

        optionElement.addEventListener('click', () => {
          input.focus();
          toggleItem(option);
          toggleClearButton();
          fillInputWithSelectedValues();
          emitSelectedItems();
          optionElement.classList.toggle('active');
        });

        if (isItemSelected(option)) {
          optionElement.classList.add('active');
        }

        list.appendChild(optionElement);
      });
      list.classList.add('tabulator-edit-select-list');

      return list;
    }

    /**
     * Open dropdown element.
     */
    function openDropdown() {
      const allOptions = parseItems(editorParams.values)
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => a.id < b.id
          ? -1
          : a.id > b.id
            ? 1
            : 0
        );

      listElement = createDropdownElement(allOptions);

      const offset = cellElement.getBoundingClientRect();

      listElement.style.minWidth = `${cellElement.offsetWidth}px`;
      listElement.style.maxHeight = '500px';

      listElement.style.top = `${offset.top + cellElement.offsetHeight}px`;
      listElement.style.left = `${offset.left}px`;

      document.body.appendChild(listElement);
    }

    input.addEventListener('focus', () => {
      if (!isOpened) {
        openDropdown();
        isOpened = true;
      }
    });

    input.addEventListener('blur', (ev) => {
      if (ev.relatedTarget) {
        /** @type HTMLElement */
        const target = ev.relatedTarget;

        if (target.hasAttribute('nrsSelectorEl')) {
          return;
        }
      }
      isOpened = false;
      listElement.parentElement.removeChild(listElement);
    });

    const disableInteractivity = (e) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    };

    // Make sure users cannot type into the input element.
    input.addEventListener('keydown', disableInteractivity);
    input.addEventListener('paste', disableInteractivity);

    clearButton.addEventListener('click', (e) => {
      selectedOptions.splice(0);
      fillInputWithSelectedValues();
      toggleClearButton();
      emitSelectedItems();
    });

    input.style.padding = '4px 1.6rem 4px 4px';
    input.style.width = '100%';
    input.style.boxSizing = 'border-box';
    input.style.cursor = 'default';

    container.appendChild(input);
    container.appendChild(clearButton);

    setTimeout(() => {
      /*
        For some unknown reason 'onRendered' callback is not fired.
        We cannot figure the initial value out during initialization.
        That's why we have to use timeout waiting for rendering.
      */
      toggleClearButton();
    }, 10);

    fillInputWithSelectedValues();

    return container;
  },
});

Tabulator.extendModule('format', 'formatters', {
  /**
   * Format boolean value to Yes/No.
   * @param {Tabulator.CellComponent} cell
   * @param {Tabulator.FormatterParams} formatterParams
   * @param {Tabulator.EmptyCallback} onRendered
   */
  nrsBoolean(cell, formatterParams, onRendered) {
    const val = cell.getValue();

    return booleanFormatter(val);
  },

  /**
   * Display edit icon.
   */
  editIcon() {
    return '<i class="fa fa-pencil"></i>';
  },

  /**
   * Display delete icon.
   */
  deleteIcon() {
    return '<i class="fa fa-trash"></i>';
  },

  /**
   * Display move type letter.
   * @param {Tabulator.CellComponent} cell
   */
  moveTypeFormatter(cell) {
    const moveType = cell.getValue();

    switch (moveType) {
      case 'inbound':
        return 'I';

      case 'outbound':
        return 'O';

      case 'crosstown_1':
        return 'X1';

      case 'crosstown_2':
        return 'X2';

      case 'cgo':
        return 'C';

      case 'terminal':
        return 'T';

      default:
        return '';
    }
  },

  /**
   * Display move type letter in dispatch sheets table.
   * @param {Tabulator.CellComponent} cell
   */
  moveTypeFormatterForDispatchSheets(cell) {
    const moveType = cell.getValue();

    switch (moveType) {
      case 'inbound':
        return 'I';

      case 'outbound':
        return 'O';

      case 'crosstown_1':
        return 'X1';

      case 'crosstown_2':
        return 'X2';

      case 'cgo':
        return 'C';

      case 'terminal':
        return 'T';

      default:
        return '';
    }
  },

  /**
   * Display trailer status letter.
   * @param {Tabulator.CellComponent} cell
   */
  stFormatter(cell) {
    const trailerState = cell.getValue();

    switch (trailerState) {
      case true:
        return 'L';

      case false:
        return 'E';

      default:
        return '';
    }
  },

  /**
   * Format raw contractor name to readable format.
   * @param {Tabulator.CellComponent} cell
   */
  contractorFormatter(cell) {
    const value = cell.getValue();

    if (value === 'pacella_trucking') {
      return 'Pacella Trucking';
    }

    if (value === 'ck_trucking') {
      return 'CK Trucking';
    }

    return '';
  },

  /**
   * Add background color for non-empty cells.
   * @param {Tabulator.CellComponent} cell
   */
  weekdayColorFormatter(cell) {
    const cellValue = cell.getValue();
    const fieldName = cell.getColumn()._column.field;
    const weekday = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    const report = cell.getData();
    const isWorking = report[`isWorkingOn${weekday}`];

    if (isWorking) {
      cell.getElement().style.backgroundColor = '#D2F5FC';
    }

    return cellValue;
  }
});

module.exports = {
  configureDropdownWithCheckboxes,
  extractFieldFilters,
  initPageDisplayElement,
  mapSortersToSortParam,
};
