const Cookies = require('js-cookie');

const csrf = Cookies.get('csrftoken');

if (csrf) {
  $.ajaxSetup({
    headers: { 'X-CSRFToken': csrf },
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('There are might be problems with HTTP requests because we could not access csrf token');
}
