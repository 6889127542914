const types = require('./types');

/**
 * Return JS DateTime from string in actual timezone.
 *
 * @param {string} time - Time string (should contain timezone).
 */
function convertUtcToUserTimezone(time) {
  const timezone = window.sessionStorage.getItem('timezone');

  const dateTime = new Date(time).toLocaleString(
     'en-US', {timeZone: timezone}
  );

  // Return `-` if time string is not DateTime
  if (dateTime === 'Invalid Date') return '-'

  return dateTime;
}

/**
 * Return string in Title Case.
 *
 * @param {string} str - String to make in title case.
 */
function toTitleCase(str) {
	str = str.toLowerCase().split(' ');

	for (let i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}

	return str.join(' ');
}


/**
 * Sleep for some time.
 *
 * @param {int} ms - Sleeping time in ms.
 */
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Run callback with delay
 * @param {Function} fn Function to call.
 * @param {number} ms Delay in milliseconds.
 */
function delay(fn, ms = 300) {
  let timer = 0

  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

/**
 * Print content on safari browser
 * @param {Function} hideCallback It's called when need to hide content that should not be printed.
 * @param {Function} showCallback It's called when printing is finished and we have to display default content.
 *
 * Safari has an issue with printing more than once. Popup appears and javascript thread is not blocked.
 * So when a user accept printing, our function had already finished and we print full page instead of
 * content we want to print.
 *
 * @see {@link https://stackoverflow.com/questions/48524702/safari-not-allowing-a-second-window-print}
 */
function safariPrint(showCallback) {
  // Safari 12, macOS
  const mediaQueryList = window.matchMedia('print');

  const onAfterPrint = mql => {
    if (!mql.matches) {
      showCallback();

      // printing is finished => unsubscribe to avoid leaks
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', onAfterPrint);
      } else {
        mediaQueryList.removeListener(onAfterPrint);
      }
    }
    window.onfocus = null;
  };

  // a tiny polyfill for a plain onafterprint
  // to handle standard window.print() dialog events

  if (mediaQueryList.addEventListener) {
    mediaQueryList.addEventListener('change', onAfterPrint);
  } else {
    mediaQueryList.addListener(onAfterPrint);
  }

  // if a user cancels printing in Safari's print confirmation dialog
  // then we will trigger a cleanup
  window.focus();
  window.onfocus = () => {
    onAfterPrint(mediaQueryList);
  };

  window.print();
}


/**
 * Prepare a sort string for API.
 * @param {string} field
 * @param {'asc' | 'desc'} direction
 */
function getOrderingQuery(field, direction) {
  return direction === 'asc' ? field : `-${field}`;
}

/**
 * Copy passed text to clipboard.
 * @param {string} text Text to copy.
 *
 * @returns {boolean} If text was copied.
 */
function copyToClipboard(text) {
  try {
    // Clipboard object is available only with secure connection.
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      // Add fallback just in case.
      const hiddenTextarea = document.createElement('textarea');

      hiddenTextarea.value = text;

      // Avoid scrolling to bottom
      hiddenTextarea.style.top = '0';
      hiddenTextarea.style.left = '0';
      hiddenTextarea.style.position = 'fixed';

      document.body.appendChild(hiddenTextarea);
      hiddenTextarea.focus();
      hiddenTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(hiddenTextarea);
    }

    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Get string with driver's working days.
 * @param {types.Driver} driver Driver data
 */
function getDriversWorkingDays(driver) {
  const working = [
    {
      index: 0,
      is_working: driver.is_working_on_monday,
      weekday: 'Monday',
    },
    {
      index: 1,
      is_working: driver.is_working_on_tuesday,
      weekday: 'Tuesday',
    },
    {
      index: 2,
      is_working: driver.is_working_on_wednesday,
      weekday: 'Wednesday',
    },
    {
      index: 3,
      is_working: driver.is_working_on_thursday,
      weekday: 'Thursday',
    },
    {
      index: 4,
      is_working: driver.is_working_on_friday,
      weekday: 'Friday',
    },
    {
      index: 5,
      is_working: driver.is_working_on_saturday,
      weekday: 'Saturday',
    },
    {
      index: 6,
      is_working: driver.is_working_on_sunday,
      weekday: 'Sunday',
    },
  ]

  const workingDays = working
    .filter(day => day.is_working)
    .map(day => day.weekday)
    .join(', ');

  return workingDays;
}

/**
 * Converts time string to number for sorting.
 * @param {string} time Time string, example: '06:00'.
 */
function getNumberFromTimeString(time) {
  return Number(time.replace(/:/g, ''));
}

/**
 * Open select.
 * @param {string} element Select to open.
 * @param {number} size Select options length.
 */
function openSelectDropdown(element, size) {
  element.attr('size', size)
}

/**
 * Close select.
 * @param {string} element Select to close.
 */
function closeSelectDropdown(element) {
  element.attr('size', 1)
}

/**
 * Download a file.
 * @param {Uint8Array} file File to download.
 * @param {name} name Name of the file.
 */
function fileDownload(file, name) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};


/**
 * Format boolean value to Yes/No.
 * @param {boolean} value
 */
function booleanFormatter(value) {
  if (value == null) {
    return '';
  }

  return value ? 'Yes' : 'No';
}

module.exports = {
  convertUtcToUserTimezone,
  toTitleCase,
  sleep,
  delay,
  safariPrint,
  getOrderingQuery,
  copyToClipboard,
  getDriversWorkingDays,
  getNumberFromTimeString,
  openSelectDropdown,
  closeSelectDropdown,
  fileDownload,
  booleanFormatter,
};
